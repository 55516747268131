import * as React from "react";
import { Provider } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LoginSection, LoginContainer } from "@homepl/onestore-ui-components/dist/Login";
import Login from "./routes/Login";
import Recovery from "./routes/Recovery";
import ConfirmNewCredentials from "./routes/ConfirmNewCredentials";
import MigrationInProgress from "./routes/MigrationInProgress";
import Slider from "./components/Slider";
import LabeledInfo from "./components/LabeledInfo";
import "./App.css";
import {
  NEW_CREDENTIALS_CONFIRM_PATH,
  NEW_CREDENTIALS_PATH,
  MIGRATION_IN_PROGRESS_PATH,
  LOGIN_PATH,
  PASSWORD_RECOVERY_PATH,
} from "./routes/paths";
import configure from "./store";
import SetupNewCredentials from "./routes/SetupNewCredentials";

const store = configure();

const NoMatch = () => <Redirect to="/" />;

export default function App() {
  const [showSidebar, setSidebarVisibility] = React.useState(true);

  React.useEffect(() => {
    const script = document.getElementById("google-recaptcha-v3");
    script.type = "text/plain";
    script.setAttribute("class", "cmplazyload");
    script.setAttribute("data-cmp-vendor", window.appConfig.reCaptchaVendor);
    script.setAttribute("data-cmp-purpose", window.appConfig.cmpPurpose);
    script.setAttribute("data-cmp-src", script.src);
    script.removeAttribute("src");
  }, []);

  return (
    <Provider store={store}>
      <LoginSection hiddenSidebar={!showSidebar}>
        <BrowserRouter>
          <GoogleReCaptchaProvider
            reCaptchaKey={window.appConfig.reCaptchaKey}
            scriptProps={{
              async: true,
              defer: false,
              appendTo: "head",
              nonce: undefined,
            }}
          >
            <LoginContainer>
              <Switch>
                <Route
                  exact
                  path={LOGIN_PATH}
                  render={({ history }) => (
                    <Login history={history} setSidebarVisibility={setSidebarVisibility} />
                  )}
                />
                <Route
                  exact
                  path={MIGRATION_IN_PROGRESS_PATH}
                  render={({ history }) => (
                    <MigrationInProgress
                      history={history}
                      setSidebarVisibility={setSidebarVisibility}
                    />
                  )}
                />
                <Route
                  exact
                  path={NEW_CREDENTIALS_PATH}
                  render={() => <SetupNewCredentials setSidebarVisibility={setSidebarVisibility} />}
                />
                <Route
                  exact
                  path={`${NEW_CREDENTIALS_CONFIRM_PATH}/:attempt`}
                  render={({ history }) => (
                    <ConfirmNewCredentials
                      history={history}
                      setSidebarVisibility={setSidebarVisibility}
                    />
                  )}
                />
                <Route
                  path={PASSWORD_RECOVERY_PATH}
                  render={({ history }) => (
                    <Recovery history={history} setSidebarVisibility={setSidebarVisibility} />
                  )}
                />
                <Redirect from="/przypomnij-haslo" to={PASSWORD_RECOVERY_PATH} />
                <Route component={NoMatch} />
              </Switch>

              <LabeledInfo
                label="Ważne"
                text={
                  <>
                    Sprawdź, jak rozpoznać prawdziwą wiadomość od home.pl {" "}
                    <a
                      target="blank"
                      className="a-link--underline"
                      href="https://blog.home.pl/2023/01/jak-rozpoznac-prawdziwa-wiadomosc-email-od-home-pl/"
                      title="Czytaj więcej"
                    >
                      Czytaj więcej
                    </a>
                  </>
                }
              />
            </LoginContainer>
          </GoogleReCaptchaProvider>
        </BrowserRouter>
        <Slider />
      </LoginSection>
    </Provider>
  );
}
